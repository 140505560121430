<template>
	<div class="main home-page">
		<!-- Hero -->
		<div class="container">
			<div class="row">
				<div class="col-lg-12 col-12">
					<div class="intro-slider-container mt-2">
						<div class="swiper-carousel intro-slider swiper-theme swiper-light swiper-nav-inside swiper-1">
							<VueSlickCarousel v-bind="settings" ref="carousel">
								<div v-for="(banner, index) in banners.slides" :key="index">
									<div class="intro-slide banner-lg"
										:style="{ 'background-image': `url(${banner.media.url})` }"
										v-lazy:background-image="banner.media.url">
										<div class="intro">
											<div class="content">
												<h3 v-if="index > 0">{{ banner.title }}</h3>
												<!-- <h3><span>up to</span> 20% <span>off</span></h3> -->

												<!-- <h4>Nieuw: refurbished</h4>
													<p>A transformative triple-camera system that
														adds tons of
														capability without complexity</p> -->
											</div>
											<div class="action">
												<router-link to="">
													<img src="@/esf_weert_mobilesupplies/assets/static/images/icons/basket.png"
														class="d-inline mr-2" width="18" alt="basket" />
													<span>buy now</span>
												</router-link>
												<router-link class="bg-white text-dark" to="">with €599
													with trade in</router-link>
											</div>
										</div>
									</div>
								</div>
								<!-- <div>
									<div class="intro-slide banner-lg"
										:style="{ 'background-image': `url(${banner2})` }"
										v-lazy:background-image="banner2">
										<div class="intro">
											<div class="title">
												<a>clearance</a>
											</div>
											<div class="content">
												<h3>power tools<br>up to <span>30% off</span></h3>
											</div>
											<div class="action">
												<router-link to=""><i class="icon-shopping-cart"
														style="font-size: 2rem"></i> buy now</router-link>
												<router-link to="">with €599 with trade in</router-link>
											</div>
										</div>
									</div>
								</div>
								<div>
									<div class="intro-slide banner-lg"
										:style="{ 'background-image': `url(${banner3})` }"
										v-lazy:background-image="banner3">
										<div class="intro">
											<div class="title">
												<a>clearance</a>
											</div>
											<div class="content">
												<h3>power tools<br>up to <span>30% off</span></h3>
											</div>
											<div class="action">
												<router-link to=""><i class="icon-shopping-cart"
														style="font-size: 2rem"></i> buy now</router-link>
												<router-link to="">with €599 with trade in</router-link>
											</div>
										</div>
									</div>
								</div> -->
							</VueSlickCarousel>
							<div class="swiper-nav">
								<div class="swiper-prev" @click="showPrev">
									<!-- <i class="icon-angle-left"></i> -->
									<img src="@/esf_weert_mobilesupplies/assets/static/images/icons/home-banner-prev-icon.png"
										style="position: absolute; width: 11px;" alt="prev">
								</div>
								<div class="swiper-next" @click="showNext">
									<!-- <i class="icon-angle-right"></i> -->
									<img src="@/esf_weert_mobilesupplies/assets/static/images/icons/home-banner-next-icon.png"
										style="position: absolute; width: 11px;" alt="next">
								</div>
							</div>
							<!-- <div class="swiper-dots swiper-dots-inner"></div> -->
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- Hero -->

		<!-- custom-features -->
		<div class="container d-none d-md-block">
			<div class="custom-features">
				<div class="row mt-2 justify-content-center">
					<div class="col-md-11">
						<div class="row">
							<div class="col-md-3 text-center p-4"><i class="icon-check text-primary"></i>
								{{ $t('15_years_of_experience') }}
							</div>
							<div class="col-md-3 text-center p-4"><i class="icon-check text-primary"></i>
								{{ $t('more_than_850,000_customers') }}
							</div>
							<div class="col-md-3 text-center p-4"><i class="icon-check text-primary"></i>
								{{ $t('free_shipping_NL') }}
							</div>
							<div class="col-md-3 text-center p-4"><i class="icon-check text-primary"></i>
								{{ $t('pay_afterwards') }}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- custom-features -->

		<!-- custom-features scroll-->
		<div class="container d-block d-md-none">
			<div class="custom-features">
				<div class="row my-2 justify-content-center">
					<div class="col-md-12 d-flex">
						<marquee class="text-center p-4">
							<i class="icon-check text-primary"></i>
							{{ $t('15_years_of_experience') }}
							<i class="icon-check text-primary ml-5"></i>
							{{ $t('more_than_850,000_customers') }}
							<i class="icon-check text-primary ml-5"></i>
							{{ $t('free_shipping_NL') }}
							<i class="icon-check text-primary ml-5"></i>
							{{ $t('pay_afterwards') }}
						</marquee>
					</div>
				</div>
			</div>
		</div>
		<!-- custom-features -->

		<!-- Brands -->
		<div class="container">
			<div class="populaire-merken">
				<div class="row">
					<div class="col-md-12 d-none d-md-block">
						<div class="section-title">
							<div>
								<p class="title"><span>{{ $t('popular_brands') }}</span></p>
							</div>

							<!-- <router-link class="link" to="">See All Products</router-link>  -->
						</div>
						<!-- <h1 class="title mt-4 mb-5">{{ $t('popular_brands') }}</h1> -->
					</div>
				</div>
				<div class="row">
					<div class="col-md-12">
						<VueSlickCarousel v-bind="settings2">
							<div v-for="(item, index) in popularBrands.slides" :key="index">
								<router-link :to="item.link">
									<div class="p-2 p-md-3">
										<!-- :style="{ 'background-image': `url(${item.media.url})` }" -->
										<div class="card" v-lazy:background-image="item.media.url">
											<div class="populaire-title">
												<h4>{{ item.title }}</h4>
											</div>
										</div>
									</div>
								</router-link>
							</div>
						</VueSlickCarousel>
					</div>
				</div>
			</div>
		</div>
		<!-- Brands -->

		<!-- New Products -->
		<product-section type="new_in_the_range" :products="newInTheRange" v-if="loaded"></product-section>
		<!-- New Products -->

		<!-- In the action -->
		<product-section type="in_the_action" :products="inTheActionProducts" v-if="loaded"></product-section>
		<!-- In the action -->

		<!-- best seller -->
		<product-section type="best_seller" :products="bestSellerProducts" v-if="loaded"></product-section>
		<!-- best seller -->

		<!-- blog -->
		<blog-section title="blog" :blogs="blogsShow"></blog-section>
		<!-- blog -->

		<!-- <div v-html="mobileHomeHighlights.content"></div> -->

		<!-- Newsletter -->
		<!-- <dialog-box width="70%" title="" :isVisible="newsletterShow" :handleClose="handleNewsletterClose">
				<template v-slot:default>
					<newsletter-modal />
				</template>
</dialog-box> -->
		<!-- Newsletter -->
	</div>
</template>

<script>
// import { mapGetters } from 'vuex';
import BlogSection from '@/esf_weert_mobilesupplies/core/components/partial/home/BlogSection';
import ProductSection from '@/esf_weert_mobilesupplies/core/components/partial/home/ProductSection';
// import NewsletterModal from '@/esf_weert_mobilesupplies/core/components/elements/modals/NewsletterModal';
// import DialogBox from '@/esf_weert_mobilesupplies/core/components/elements/DialogBox';

// import Repository, { baseUrl } from '~/repositories/repository.js';
// import { attrFilter } from '@/esf_weert_mobilesupplies/utilities/common';
// import { homeData } from '@/esf_weert_mobilesupplies/utilities/data';

import VueSlickCarousel from "vue-slick-carousel";
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css';
import config from '../config';

export default {
	components: {
		BlogSection,
		ProductSection,
		VueSlickCarousel,
		// NewsletterModal,
		// DialogBox
	},
	data: function () {
		return {
			loaded: false,
			newInTheRange: [],
			inTheActionProducts: [],
			bestSellerProducts: [],
			topProducts: [],
			featuredProducts: [],
			// newsletterShow: false,
			settings: {
				// dots: true,
				// dotsClass: "swiper-dots swiper-dots-inner",
				// edgeFriction: 0.35,
				// infinite: false,
				arrows: false,
				speed: 500,
				autoplay: true,
				autoplaySpeed: 3000,
				pauseOnHover: true,
				slidesToShow: 1,
				slidesToScroll: 1,
				// responsive: [
				// 	{
				// 		breakpoint: 992,
				// 		settings: {
				// 			slidesToShow: 2.5,
				// 			dots: false,
				// 		},
				// 	},
				// 	{
				// 		breakpoint: 767,
				// 		settings: {
				// 			slidesToShow: 1.5,
				// 			dots: false,
				// 		},
				// 	},
				// 	{
				// 		breakpoint: 480,
				// 		settings: {
				// 			slidesToShow: 1.5,
				// 			slidesToScroll: 1,
				// 		},
				// 	},
				// ],
			},
			settings2: {
				// dots: true,
				arrows: true,
				speed: 500,
				autoplay: true,
				autoplaySpeed: 3000,
				pauseOnHover: true,
				slidesToShow: 3,
				slidesToScroll: 1,
				centerMode: true,
				responsive: [
					{
						breakpoint: 992,
						settings: {
							slidesToShow: 3,
						},
					},
					{
						breakpoint: 767,
						settings: {
							slidesToShow: 2,
						},
					},
					{
						breakpoint: 375,
						settings: {
							slidesToShow: 1,
						},
					},
				],
			},
		};
	},
	computed: {
		cmsDefaultPageContent() {
			return this.$store.state.cmsPage.default;
		},
		banners() {
			return this.$store.getters["sliders/getSliderByIdentifier"](
				"home_banner"
			)
		},
		popularBrands() {
			return this.$store.getters["sliders/getSliderByIdentifier"](
				"popular"
			)
		},
		blogsShow() {
			return this.$store.getters["home/getHomeBlogs"].slice(0, 3);
		},
		// mobileHomeHighlights() {
		//     return this.$store.getters[`cmsBlock/getCmsBlockByIdentifier`](
		//         "mobile_home_highlights"
		//     );
		// }
	},
	created: function () {
		this.getProducts();
	},
	// mounted: function () {
	// 	// if (this.newsletterShow) {
	// 		setTimeout(() => {
	// 			if (this.$route.path == '/') {
	// 				this.newsletterShow = true;
	// 				// console.log("newsletterShow")
	// 				// this.$modal.show(
	// 				// 	NewsletterModal,
	// 				// 	{},
	// 				// 	{ width: '970', height: 'auto', adaptive: true }
	// 				// );
	// 			}
	// 		}, 8000);
	// 	// }
	// },
	methods: {
		getProducts: async function () {
			this.loaded = false;
			// var data = JSON.stringify({
			// 	query: `query Products($search: String, $filter: ProductAttributeFilterInput) {
			// 		products(search: $search, filter: $filter) {
			// 			total_count
			// 		}
			// 	}`,
			// 	variables: { "search": null, "filter": {} }
			// });

			// API CALL
			try {
				this.newInTheRange = await this.$store.getters["home/getHomeNewSofas"]
				this.inTheActionProducts = await this.$store.getters["home/getLatestTrendsProducts"]
				this.bestSellerProducts = await this.$store.getters["home/getHomeBestSellerProducts"];
				this.loaded = true;
			} catch (error) {
				console.log(error);
			}
			// console.log('homeData', homeData.products)
			// this.loaded = false;
			// this.products = await homeData.products;
			// this.featuredProducts = attrFilter(
			// 	this.products,
			// 	'featured'
			// );
			// this.topProducts = attrFilter(this.products, 'top');
			// this.blogs = homeData.blogs;
			// this.loaded = true;
		},
		showNext() {
			this.$refs.carousel.next()
		},
		showPrev() {
			this.$refs.carousel.prev();
		},
		handleNewsletterClose() {
			this.newsletterShow = false;
		}
	},
	metaInfo() {
		return {
			meta: [
				{
					name: "title",
					content: this.cmsDefaultPageContent
						? this.cmsDefaultPageContent.meta_title
						: "test",
				},
				{
					name: "keywords",
					content: this.cmsDefaultPageContent
						? this.cmsDefaultPageContent.meta_keywords
						: "",
				},
				{
					name: "description",
					content: this.cmsDefaultPageContent
						? this.cmsDefaultPageContent.meta_description
						: "",
				},
			],
			title: this.$t("home"),
			link: [
				{
					rel: "canonical",
					href:
						config.app_url +
						(/\/$/.test(this.$route.fullPath)
							? this.$route.fullPath
							: this.$route.fullPath.replace(/^\//, "") + "/"),
				},
			],
		};
	},
};
</script>