<template>
	<div class="container post">
		<div class="section-title">
			<div>
				<p class="title"><span>{{ $t(title) }}</span></p>
			</div>
		</div>
		<VueSlickCarousel v-bind="blogSliderSettings">
			<div class="px-3" v-for="(blog, index) in blogs" :key="index">
				<blog-four :blog="blog" class="mb-2"></blog-four>
			</div>
		</VueSlickCarousel>
		<div class="row">
			<div class="col-md-12 text-right">
				<router-link to="/blog/" class="text-dark underline"><strong>{{ $t('view_all_blog')
						}}</strong></router-link>
			</div>
		</div>
	</div>
</template>

<script>

import VueSlickCarousel from "vue-slick-carousel";
import BlogFour from '@/esf_weert_mobilesupplies/core/components/elements/blogs/BlogFour';
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css';

export default {
	components: {
		BlogFour,
		VueSlickCarousel,
	},
	props: {
		blogs: Array,
		title: String
	},
	data: function () {
		return {
			blogSliderSettings: {
				// dots: true,
				arrows: false,
				dotsClass: "slick-dots custom-dot-class",
				// edgeFriction: 0.35,
				infinite: false,
				speed: 500,
				autoplay: true,
				autoplaySpeed: 2000,
				pauseOnHover: true,
				slidesToShow: 3,
				slidesToScroll: 1,
				spacing: 20,
				responsive: [
					{
						breakpoint: 992,
						settings: {
							slidesToShow: 3,
						},
					},
					// {
					// 	breakpoint: 768,
					// 	settings: {
					// 		slidesToShow: 3,
					// 	},
					// },
					{
						breakpoint: 480,
						settings: {
							slidesToShow: 2,
						},
					},
				],
			},
			// carouselSetting: {
			// 	...carouselSettingDefault,
			// 	slidesPerView: 3,
			// 	spaceBetween: 20,
			// 	breakpoints: {
			// 		480: {
			// 			slidesPerView: 1
			// 		},
			// 		768: {
			// 			slidesPerView: 2
			// 		},
			// 		992: {
			// 			slidesPerView: 3
			// 		}
			// 	},
			// 	pagination: {
			// 		el: '.blog-posts .swiper-dots',
			// 		clickable: true
			// 	}
			// }
		};
	},
};
</script>
